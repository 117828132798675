
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '../styles/globals.scss'
import Router, { useRouter } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import NProgress from 'nprogress'
import { SessionProvider } from 'next-auth/react'
import { Montserrat } from 'next/font/google'
import { useApollo } from '@/api/apollo'
import { initCookies, refererCookie } from '../libs/cookies'

import { AnalyticsProvider } from '@/context/analyticsContext'
import MainLayout from '@/components/layouts/MainLayout'
import MeProvider from '@/context/meContext'
import Analytics from '@/components/atoms/Analytics'

const montserrat = Montserrat({
  weight: ['300', '400', '500', '600', '700', '800', '900'],
  subsets: ['latin', 'latin-ext'],
  variable: '--font-montserrat',
})

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function Bulldogjob({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  initCookies()
  const apolloClient = useApollo(pageProps)
  const router = useRouter()

  useEffect(() => {
    refererCookie()
  }, [])

  const defaultLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>

  // const getLayout = Component.getLayout || defaultLayout
  const getLayout = (page: ReactElement) => (
    <ApolloProvider client={apolloClient}>
      <SessionProvider session={session}>
        <AnalyticsProvider>
          <Analytics />
          <MeProvider>
            <style jsx global>{`
              :root {
                --font-montserrat: ${montserrat.style.fontFamily};
              }
            `}</style>
            {(Component.getLayout ?? defaultLayout)(page)}
          </MeProvider>
        </AnalyticsProvider>
      </SessionProvider>
    </ApolloProvider>
  )

  if (router.pathname === '/_error') return <Component {...pageProps} />

  return getLayout(<Component {...pageProps} />)
}
const __Page_Next_Translate__ = Bulldogjob


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  