import { ReactNode } from 'react'

interface Props {
  children: string | ReactNode
  className?: string
}

const ListNavItem: React.FC<Props> = ({ children, className }) => (
  <li className={`my-4 ${className}`}>{children}</li>
)

export default ListNavItem
