import { Menu, Transition } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { UserIcon } from '@heroicons/react/24/solid'

interface IProps {
  className: string
}

const NavUser = ({ className, ...rest }: IProps) => {
  const { t } = useTranslation('common')

  return (
    <Menu
      as='nav'
      className={`relative flex items-center text-left ${className}`}
      {...rest}
    >
      <Menu.Button
        className='inline-flex justify-center items-center text-xl border py-4 px-4 rounded-full hover:bg-gray transition-colors duration-300'
        style={{ borderColor: '#AEADB1' }}
        aria-label={t('common:login')}
      >
        <UserIcon
          className='w-6 text-violet-200 hover:text-violet-100'
          aria-hidden='true'
        />
      </Menu.Button>
      <Transition
        enter='transition duration-100 ease-out'
        enterFrom='transform scale-95 opacity-0'
        enterTo='transform scale-100 opacity-100'
        leave='transition duration-75 ease-out'
        leaveFrom='transform scale-100 opacity-100'
        leaveTo='transform scale-95 opacity-0'
      >
        <Menu.Items className='absolute right-0 w-56 mt-8 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='px-1 py-1 '>
            <Menu.Item>
              {({ active }: any) => (
                <div className=' w-full px-2 py-2 text-sm font-bold'>
                  {t('as_it_specialist')}
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: any) => (
                <a
                  href='/auth/login'
                  className={`${
                    active ? 'bg-violet-500 text-white' : 'text-gray-900'
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm duration-100 hover:bg-action-300`}
                >
                  {t('login')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: any) => (
                <a
                  href='/auth/register'
                  className={`${
                    active ? 'bg-violet-500 text-white' : 'text-gray-900'
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm duration-100 hover:bg-action-300`}
                >
                  {t('register')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: any) => (
                <div className=' w-full px-2 py-2 text-sm font-bold'>
                  {t('as_employer')}
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: any) => (
                <a
                  href='/auth/sign_in'
                  className={`${
                    active ? 'bg-violet-500 text-white' : 'text-gray-900'
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm duration-100 hover:bg-action-300`}
                >
                  {t('login')}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: any) => (
                <a
                  href='/auth/sign_up'
                  className={`${
                    active ? 'bg-violet-500 text-white' : 'text-gray-900'
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm duration-100 hover:bg-action-300`}
                >
                  {t('register')}
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default NavUser
