// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from '@sentry/nextjs'

// const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

// if (process.env.NEXT_PUBLIC_ENV == 'production') {
//   Sentry.init({
//     dsn:
//       SENTRY_DSN ||
//       'https://030f52b5dae847a3baaa236b1b4f3f47@o359877.ingest.sentry.io/3560254',
//     // Adjust this value in production, or use tracesSampler for greater control
//     tracesSampleRate: 0.1,
//     ignoreErrors: ['Non-Error promise rejection captured'],
//     // ...
//     // Note: if you want to override the automatic release value, do not set a
//     // `release` value here - use the environment variable `SENTRY_RELEASE`, so
//     // that it will also get attached to your source maps
//   })
// }
