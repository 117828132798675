import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import GuideLink from '../atoms/GuideLink'
import ListNavItem from '../atoms/ListNavItem'

interface Element {
  title: string
  url: string
  external?: boolean
  showPl?: boolean
  showEn?: boolean
}

interface Props {
  title: string
  elements: Element[]
  withLang?: boolean
}
const FooterNav: React.FC<Props> = ({ title, elements, withLang }) => {
  const { lang } = useTranslation()
  const router = useRouter()

  const filterByLang = (element: Element) => {
    if (lang === 'pl') {
      return element.showPl
    } else {
      return element.showEn
    }
  }

  return (
    <div className='w-full md:w-auto mb-6 md:mb-0'>
      <header className='mb-6'>
        <h4 className='text-3xl md:text-2xl text-action'>{title}</h4>
      </header>
      <nav>
        <ul>
          {elements.filter(filterByLang).map((element: Element, index) => (
            <ListNavItem key={index}>
              {element.url === '/bulldog-guide' ? (
                <GuideLink>
                  <span className='hover:text-primary  transition-colors cursor-pointer'>
                    {element.title}
                  </span>
                </GuideLink>
              ) : (
                <Link
                  href={element.url}
                  locale={lang}
                  prefetch={false}
                  title={element.title}
                  className='hover:text-primary  transition-colors'
                >
                  {element.title}
                </Link>
              )}
            </ListNavItem>
          ))}
          {withLang &&
            router.domainLocales?.map((item, index) => (
              <ListNavItem
                key={index}
                className={lang === item.defaultLocale ? 'hidden' : ''}
              >
                <Link
                  href={router.asPath}
                  locale={item.defaultLocale}
                  prefetch={false}
                  className='hover:text-primary  transition-colors'
                >
                  {lang === 'pl' ? 'English version' : 'Polish version'}
                </Link>
              </ListNavItem>
            ))}
        </ul>
      </nav>
    </div>
  )
}

export default FooterNav
