interface Props {
  width: number
  className?: string
}

const FacebookIcon = ({ width, className }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
  >
    <path d='M23.999 12.0728C23.999 5.44449 18.6268 0.0722656 11.9995 0.0722656C5.37222 0.0722656 0 5.44449 0 12.0728C0 18.061 4.38818 23.0252 10.1244 23.9253V15.5409H7.07829V12.0718H10.1244V9.42865C10.1244 6.42053 11.9165 4.75946 14.6566 4.75946C15.9697 4.75946 17.3427 4.99347 17.3427 4.99347V7.94659H15.8307C14.3406 7.94659 13.8756 8.87163 13.8756 9.82067V12.0728H17.2037L16.6717 15.5419H13.8756V23.9263C19.6118 23.0262 24 18.062 24 12.0728H23.999Z' />
  </svg>
)
export default FacebookIcon
