import Cookies from 'js-cookie'

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function deleteCookie(name, path) {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
  }
}

export function initCookies() {
  if (typeof window != 'undefined') {
    window.Cookies = Cookies
  }
}

export function refererCookie() {
  const cookie = Cookies.get('rf')

  if (cookie) {
    return cookie
  } else {
    const url = window.location.href
    let referer = url.includes('utm_source') ? url : document.referrer
    Cookies.set('rf', referer, { expires: 2 / 24, secure: true })
    return referer
  }
}
