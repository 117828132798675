import { useSession } from 'next-auth/react'

interface IProps {
  children: JSX.Element | string
}

const GuideLink = ({ children }: IProps) => {
  const { data: session } = useSession()

  return (
    <a
      className='cursor-pointer w-full xl:w-auto'
      href={session ? '/account/guide' : '/bulldog-guide'}
    >
      {children}
    </a>
  )
}

export default GuideLink
