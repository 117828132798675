module.exports = {
  locales: ['pl', 'en'],
  defaultLocale: 'pl',
  localeDetection: false,
  domains: [
    {
      domain: process.env.PL_HOST,
      defaultLocale: 'pl',
    },
    {
      domain: process.env.EN_HOST,
      defaultLocale: 'en',
    },
  ],
  pages: {
    '*': ['common', 'footer', 'author', 'validation_messages'],
    '/': ['home'],
    '/articles/[slug]': ['blog', 'help'],
    '/for-employers/think-it-magazyn-hr': ['forEmployers'],
    '/for-employers/pricing': ['forEmployers', 'pricing'],
    '/for-employers/blog/[[...slug]]': ['hr_blog'],
    '/for-employers/blog/[slug]': ['hr_blog', 'blog'],
    '/news/[slug]': ['blog', 'help'],
    '/readme/[[...slug]]': ['blog', 'help'],
    '/companies/jobs/[slug]': [
      'jobs',
      'hwm',
      'tools',
      'test',
      'code',
      'benefit',
      'how_we_work',
    ],
    '/companies/jobs/[[...slug]]': ['search'],
    '/companies/jobs/saved': ['search'],
    '/readme/[slug]': ['blog', 'help'],
    '/readme/authors/[slug]': ['blog', 'author'],
    '/readme/companies/[slug]': ['company'],
    '/companies/profiles/[[...slug]]': ['companies', 'review'],
    '/companies/profiles/[slug]': ['company', 'review'],
    '/companies/profiles/reviews-faq': ['reviewFaq'],
    '/it-report': ['it_report'],
    '/auth/choose': ['marketing_messages'],
  },
}
