type Sizes = 'normal' | 'medium' | 'big' | 'large'

interface IProps {
  url?: string | null
  size?: Sizes
  className?: string
  remote?: boolean
  rounded?: boolean
  alt?: string
}

const sizes = {
  normal: 40,
  medium: 64,
  big: 80,
  large: 200,
}

const Avatar = ({
  url = null,
  size = 'normal',
  className,
  remote = false,
  rounded = true,
  alt = '',
  ...rest
}: IProps) => (
  <div className={`${className || ''}`} {...rest}>
    {url ? (
      <>
        {rounded ? (
          <img
            src={url}
            alt={alt}
            width='1'
            height='1'
            loading='lazy'
            style={{
              width: sizes[size],
              height: sizes[size],
              minWidth: sizes[size],
              objectFit: 'cover',
            }}
            className='rounded-full'
          />
        ) : (
          <img
            src={url}
            alt={alt}
            loading='lazy'
            style={{
              width: '100%',
              height: sizes[size],
              minWidth: sizes[size],
              objectFit: 'contain',
            }}
          />
        )}
      </>
    ) : (
      <div
        className='bg-gray-300 rounded-full'
        style={{ width: sizes[size], height: sizes[size] }}
      />
    )}
  </div>
)

export default Avatar
