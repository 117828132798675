import React, { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Cookies from 'js-cookie'
import { useAnalyticsPush } from '@/context/analyticsContext'

const CookieBar = () => {
  const { setConsentsReady } = useAnalyticsPush()
  const { t } = useTranslation('common')
  const [settings, setSettings] = useState<boolean>(false)
  const [analytics, setAnalytics] = useState<boolean>(true)
  const [marketing, setMarketing] = useState<boolean>(true)
  const [data, setData] = useState<boolean[] | undefined>()
  const [init, setInit] = useState(false)

  const handleAnalytics = () => {
    setAnalytics(!analytics)
  }

  const handleMarketing = () => {
    setMarketing(!marketing)
  }

  const handleAll = () => {
    setData([true, true])
  }

  const handleSelected = () => {
    setData([analytics, marketing])
  }

  const saveConsentsToCookie = (
    analyticsConsent: boolean,
    marketingConsent: boolean
  ) => {
    const value = [
      analyticsConsent ? '1' : '0',
      marketingConsent ? '1' : '0',
    ].join(',')
    Cookies.set('consents', value, { expires: 365 })
  }

  const getConsentsFromCookie = () => {
    const value = Cookies.get('consents')
    if (value) return value.split(',').map((el) => el === '1')
    return undefined
  }

  const sendConsents = (
    analyticsConsent: boolean,
    marketingConsent: boolean
  ) => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    function gtag() {
      // @ts-ignore
      dataLayer.push(arguments)
    }
    // @ts-ignore
    gtag('consent', 'update', {
      functionality_storage: 'granted',
      security_storage: 'granted',
      analytics_storage: 'granted',
      ad_storage: marketingConsent ? 'granted' : 'denied',
      ad_personalization: marketingConsent ? 'granted' : 'denied',
      ad_user_data: marketingConsent ? 'granted' : 'denied',
      personalization_storage: analyticsConsent ? 'granted' : 'denied',
    })
    setConsentsReady(true)
  }

  useEffect(() => {
    setInit(true)
    const cookieData = getConsentsFromCookie()
    setData(cookieData)
  }, [])

  useEffect(() => {
    if (!data) return

    saveConsentsToCookie(data[0], data[1])
    sendConsents(data[0], data[1])
  }, [data])

  return (
    <>
      {init && !data && (
        <div className='z-20 fixed h-auto bottom-0 left-0 right-0 bg-white py-4 px-0 sm:p-10 shadow-article border-t-1 border-gray-100 cookie-bar'>
          <div className='md:flex justify-between block container'>
            <div className='md:w-2/3 font-light md:mr-10'>
              {t('common:cookie_info')}
              <a href='/privacy_policy' className='underline'>
                {t('common:cookie_info_link')}
              </a>
              {settings && (
                <div className='py-5'>
                  <label className='mr-5'>
                    <input
                      className='mr-2'
                      type='checkbox'
                      checked={analytics}
                      onChange={handleAnalytics}
                    />
                    {t('common:cookie_analytics')}
                  </label>
                  <label className='p-5'>
                    <input
                      className='mr-2'
                      type='checkbox'
                      checked={marketing}
                      onChange={handleMarketing}
                    />
                    {t('common:cookie_ad')}
                  </label>
                </div>
              )}
            </div>
            <div className='md:w-1/3 flex md:flex-row flex-col-reverse gap-4 mt-4 justify-center items-center'>
              {settings ? (
                <button
                  type='button'
                  className='h-14 md:h-16 items-center justify-center font-normal focus:outline-none transition ease-in-out duration-300 cursor-pointer rounded-full whitespace-nowrap relative inline-block py-2 px-7 border-3 border-black hover:bg-gray-dark'
                  onClick={handleSelected}
                >
                  {t('common:cookie_save')}
                </button>
              ) : (
                <button
                  type='button'
                  className='md:m-10 m-4 font-light cursor-pointer'
                  onClick={() => {
                    setSettings(true)
                  }}
                >
                  {t('common:cookie_settings')}
                </button>
              )}
              <button
                type='button'
                className='px-3 h-14 md:h-16 items-center justify-center md:text-sm focus:outline-none transition ease-in-out duration-300 cursor-pointer rounded-full whitespace-nowrap relative inline-block bg-primary hover:bg-primary-hover text-white border-3 border-primary hover:border-primary-hover font-normal'
                onClick={handleAll}
              >
                {t('common:cookie_accept')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieBar
