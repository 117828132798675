import { useState } from 'react'
import CookieBar from '../organisms/CookieBar'
import Footer from '../organisms/Footer'
import HeaderBarEmployee from '../organisms/HeaderBarEmployee'

export default function MainLayout({ children }: any) {
  const [spacerHeight, setSpacerHeight] = useState(0)

  return (
    <>
      <CookieBar />
      <HeaderBarEmployee setSpacerHeight={setSpacerHeight} />
      <div style={{ height: spacerHeight }} />
      {children}
      <Footer />
    </>
  )
}
