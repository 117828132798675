import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { SchemaLink } from '@apollo/client/link/schema'
import { getSession } from 'next-auth/react'
//@ts-ignore
import { createUploadLink } from 'apollo-upload-client'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import { useMemo } from 'react'

type SchemaContext =
  | SchemaLink.ResolverContext
  | SchemaLink.ResolverContextFunction

const httpFrontLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
})

const httpLink = createUploadLink({
  uri: process.env.API_URL,
})

const getAuthLink = (context: any) =>
  setContext(async (_, { headers }) => {
    let session = null
    if (context) {
      session = await getSession(context)
    } else {
      session = await getSession()
    }

    const modifiedHeader = {
      headers: {
        ...headers,
        authorization: session?.authToken ? `Bearer ${session.authToken}` : '',
      },
    }

    return modifiedHeader
  })

const authLink = setContext(async (_, { headers }) => {
  let session = null

  session = await getSession()

  const modifiedHeader = {
    headers: {
      ...headers,
      authorization: session?.authToken ? `Bearer ${session.authToken}` : '',
    },
  }

  return modifiedHeader
})

const serviceLink = setContext(async (_, { headers }) => {
  const modifiedHeader = {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.AUTH_SERVICE_TOKEN}`,
    },
  }
  return modifiedHeader
})

const loginLink = (token: string) =>
  setContext(async (_, { headers }) => {
    const modifiedHeader = {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
    return modifiedHeader
  })

export const serviceClient = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: serviceLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export const loginClient = (token: string) =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: loginLink(token).concat(httpLink),
    cache: new InMemoryCache(),
  })

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

function createApolloClient(ctx?: SchemaContext) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link:
      typeof window === 'undefined'
        ? getAuthLink(ctx).concat(httpLink)
        : getAuthLink(ctx).concat(httpFrontLink),
    cache: new InMemoryCache(),
  })
}

type InitialState = NormalizedCacheObject | undefined
interface InitApollo {
  initialState?: any
  ctx?: SchemaContext
  forceClientReload?: boolean
}

export function initializeApollo({
  ctx,
  initialState,
  forceClientReload,
}: InitApollo) {
  let _apolloClient

  if (forceClientReload) {
    _apolloClient = createApolloClient(ctx || undefined)
  } else {
    _apolloClient = apolloClient ?? createApolloClient(ctx || undefined)
  }

  if (initialState) {
    const existingCache = _apolloClient.extract()

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray: any, sourceArray: any) => [
        ...sourceArray,
        ...destinationArray.filter((d: any) =>
          sourceArray.every((s: any) => !isEqual(d, s))
        ),
      ],
    })

    _apolloClient.cache.restore(data)
  }

  if (typeof window === 'undefined') return _apolloClient
  if (!apolloClient || forceClientReload) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client: any, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(
    () => initializeApollo({ initialState: state }),
    [state]
  )
  return store
}
