import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styles from './styles.module.scss'

const NavLanguageChanger = () => {
  const router = useRouter()
  const { lang } = useTranslation()

  if (!router.domainLocales) {
    return <></>
  }

  return (
    <>
      {router.domainLocales?.map((item, index) => (
        <div
          key={index}
          className={` ${lang === item.defaultLocale ? 'hidden' : ''} ${
            styles.languageChanger
          }`}
        >
          <Link
            href={router.asPath}
            locale={item.defaultLocale}
            prefetch={false}
            legacyBehavior
          >
            <button className='' type='button'>
              {lang === 'pl' ? 'EN' : 'PL'}
            </button>
          </Link>
        </div>
      ))}
    </>
  )
}

export default NavLanguageChanger
