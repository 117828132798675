import {
  Admin,
  Candidate,
  User,
  MeDocument,
  MeQuery,
  MeQueryVariables,
} from 'generated/apolloComponents'
import React, { useContext, useState, useEffect } from 'react'
import { useSession, signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { initializeApollo } from '@/api/apollo'

export const MeContext = React.createContext<any>(null)

const MeProvider = ({ children }: any) => {
  const { data: session } = useSession()
  const router = useRouter()
  const [me, setMe] = useState<Candidate | Admin | User>()
  const [prevSession, setPrevSession] = useState<any>()

  const loadMe = async function (noCache = false) {
    // skip 'me' loading if session is empty and there is no 'me'
    if (!session?.authToken && !me) {
      return
    }

    // if authToken not matching then reload apollo client
    let apolloClient
    if (session?.authToken == prevSession?.authToken) {
      apolloClient = initializeApollo({})
    } else {
      apolloClient = initializeApollo({ forceClientReload: true })
      setPrevSession(session)
    }

    const { data } = await apolloClient.query<MeQuery, MeQueryVariables>({
      query: MeDocument,
      ...(noCache && { fetchPolicy: 'no-cache' }),
    })

    if (!data.me) {
      signOut()
    }

    setMe(data.me as Candidate | Admin | User)
  }

  useEffect(() => {
    loadMe()
  }, [session, router.asPath])

  const value = {
    me,
    setMe,
    loadMe,
    signOut,
    session,
  }

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>
}

export const useMe = () => {
  const context = useContext(MeContext)
  if (context === undefined) {
    throw new Error('useMe must be used within a PersonProvider')
  }
  return context
}

export default MeProvider
