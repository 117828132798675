export type FooterLanding = {
  id: string
  showInMenu: boolean
  url: string
  menuTitle: string
}

type FooterLandings = {
  [key: string]: FooterLanding[]
}

export const getFooterLandings = (lang: string) => {
  const landings: FooterLandings = {
    en: [
      {
        id: '4',
        showInMenu: true,
        url: '/for-employers/pricing',
        menuTitle: 'Offer for companies',
      },
      {
        id: '5',
        showInMenu: true,
        url: '/for-employers/blog',
        menuTitle: 'Readme for HR',
      },
      {
        id: '6',
        showInMenu: true,
        url: '/auth/sign_up',
        menuTitle: 'Create free employer profile',
      },
    ],
    pl: [
      {
        id: '1',
        showInMenu: true,
        url: '/for-employers/pricing',
        menuTitle: 'Oferta dla firm',
      },
      {
        id: '2',
        showInMenu: true,
        url: '/for-employers/blog',
        menuTitle: 'Readme for HR',
      },
      {
        id: '3',
        showInMenu: true,
        url: '/for-employers/think-it-magazyn-hr',
        menuTitle: 'Think IT Magazyn HR',
      },
      {
        id: '4',
        showInMenu: true,
        url: '/auth/sign_up',
        menuTitle: 'Załóż darmowy profil pracodawcy',
      },
    ],
  }

  return landings[lang]
}
