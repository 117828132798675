import React from 'react'
import styles from './styles.module.scss'

type Props = {
  menuVisible: boolean
  handleMenuTrigger: (e: React.MouseEvent) => void
}

const MenuHamburger = ({ menuVisible, handleMenuTrigger }: Props) => (
  <button
    className={`${styles.menuHamburger} ${
      menuVisible ? styles.menuHamburger__expanded : ''
    }`}
    onClick={handleMenuTrigger}
    type='button'
    title='menu'
  >
    <span />
    <span />
    <span />
  </button>
)

export default MenuHamburger
