import React, { Dispatch, SetStateAction, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Logo from '../../atoms/Logo'
import NavMain from '../../molecules/NavMain'
import styles from './styles.module.scss'
import Avatar from '@/components/atoms/Avatar'
import useOuterClick from '@/components/hooks/useOuterClick'
import { useMe } from '@/context/meContext'
import NavLanguageChanger from '@/components/molecules/NavLanguageChanger'
import NavUser from '@/components/molecules/NavUser'
import MenuHamburger from '@/components/molecules/MenuHamburger'

interface IProps {
  setSpacerHeight: Dispatch<SetStateAction<number>>
}

const HeaderBarEmployee = ({ setSpacerHeight }: IProps) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false)
  const innerRef = useOuterClick((e: React.MouseEvent) => {
    setMenuVisible(false)
  })

  const { me } = useMe()
  const { t, lang } = useTranslation()

  const handleMenuTrigger = (e: React.MouseEvent) => {
    setMenuVisible(!menuVisible)
  }

  const sessionLink = (user: any) => {
    let url: string
    switch (user.__typename) {
      case 'Admin':
        url = '/admin'
        break
      case 'Candidate':
        url = '/account'
        break
      case 'User':
        url = '/dashboard'
        break
      default:
        url = '/account'
        break
    }

    return url
  }

  return (
    <div id='main-menu' className={styles['header-primary']}>
      <header
        ref={innerRef}
        className={`py-3 px-4 ${
          menuVisible ? styles['header-primary__expanded'] : ''
        }`}
      >
        <div className='lg:container'>
          <div className={styles.menu__containerWrapper}>
            <Logo className='mr-auto' />

            <div
              className={`${styles.menu__wrapper} ${
                menuVisible ? styles['menu--visible'] : ''
              }`}
            >
              <NavMain setMenuVisible={setMenuVisible} />
            </div>
            <div className={styles.menu__langChangerWrapperMobile}>
              <NavLanguageChanger />
            </div>
            {(!me || me?.__typename !== 'Candidate') && (
              <a
                href='/for-employers/pricing'
                className={`${styles.menu__employersButton}`}
              >
                {t('common:for_employers')}
              </a>
            )}
            {!me ? (
              <NavUser className={`lg:ml-0 ${menuVisible ? '' : ''}`} />
            ) : (
              <a href={sessionLink(me)} rel='nofollow' title=''>
                <Avatar url={me.avatar?.url} size='normal' remote />
              </a>
            )}
            {/* eslint-disable-next-line react/button-has-type */}
            <div className='lg:hidden'>
              <MenuHamburger
                menuVisible={menuVisible}
                handleMenuTrigger={handleMenuTrigger}
              />
            </div>
            <div className={styles.menu__langChangerWrapper}>
              <NavLanguageChanger />
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default HeaderBarEmployee
